<template>
    <div>
        <span v-if="element.serviceDetails && [1,2,3,4,8].includes(parseInt(element.extraServiceId))"
             class="label label-warning d-inline-block mr-2 pl-2 pr-2 mb-0">
            <span v-if="listServicePack.find(x => x.id === element.serviceDetails)">
                {{listServicePack.find(x => x.id === element.serviceDetails).name}} ({{listServicePack.find(x => x.id === element.serviceDetails).description}})
            </span>
        </span>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        props: {
            element: {
                type: Object,
                default() {
                    return null;
                },
            },
        },
        computed: {
            ...mapState({
                listServicePack: (state) => state.common.listServicePack,
            }),
            isQaOrOwned() {
                return this.rolesInfo.includes('QA') || this.rolesInfo.includes('OWNED')
            },
        },
        data() {
            return {

            };
        },
        created() {
            
        }
    }
</script>